import React from 'react';
import {Link, graphql} from 'gatsby';

import Layout from '../components/layout';

const NotFoundPage = ({data, location}) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <div className="notfound">
        <h3>存在しないページです</h3>
        <Link to="/">トップに戻る</Link>
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
