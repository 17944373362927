import React from 'react';
import {Link} from 'gatsby';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

import './index.scss';

const Layout = ({location, title, children}) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  let header;

  if (location.pathname === rootPath) {
    header = (
      <div className="b__header">
        <h1 className="blog-name">{title}</h1>
      </div>
    );
  } else {
    header = (
      <div className="b__header">
        <h1 className="blog-name">
          <Link to={`/`}>{title}</Link>
        </h1>
      </div>
    );
  }
  return (
    <div className="blog-main">
      <header>{header}</header>
      <main>{children}</main>
    </div>
  );
};

export default Layout;
